
import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator';
import DotsIcon from '@/assets/images/dots.svg';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import { DocumentName } from './model';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue'
import Icon from '@/components/ui/icon/Icon.vue';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import UsersModule from '@/store/modules/users/UsersModule';
import { getCookie, rmCookie, setCookie } from '@/composables/cookie';
import { MAIN_API_HOST } from '@/services/helpers/constants';
@Component({
	name: 'DocumentSummary',
	components:
	{
		ContextMenuSpawner,
		ContextMenuLink,
		Icon,
		DeleteContext,
	},
})
export default class DocumentSummary extends Vue
{
	@Prop({
		default:
		{
			title: '',
			subtitle: '',
		},
	})
	value: DocumentName;
	public dotsIcon = DotsIcon;
	@Prop({
	default: () => ({
		title: 'Documents.Document.TitlePlaceholder',
		subtitle: 'Documents.Document.SubtitlePlaceholder',
		}),
	})
	placeholders: DocumentName;

	@Prop({ default: false }) readonly: boolean;
	@Prop({ }) type: string;

	public isOptionsDocumentVisible = false;
	public showDeleteContext        = false;
	public hasNameOption            = false;
	private innerTitle              : string;
	public inputValue               = ''
	public subtitle                 = '';
	public title                    = '';

	public created(): void
	{
		this.title = this.value.title;
		this.subtitle = this.value.subtitle;
	}

	mounted()
	{
		setCookie('documentTitle', this.title);
		const areaEl = this.$refs.documentNameArea as HTMLElement
		const subtitleEl = this.$el.querySelector('.document-name__subtitle') as HTMLElement;

		this.$nextTick(() =>
		{
			areaEl.focus();
			this.autoSize(areaEl)
			this.autoSize(subtitleEl)
		})
	}
	// @Watch('title')
	// 	onTitleChange(newTitle: string) {
	// 	setCookie('documentTitle', newTitle || 'Без названия');
	// }
	@Watch('type')
	typeChanged(val: string)
	{
		const areaEl = this.$refs.documentNameArea as HTMLElement
		this.$nextTick(() =>
		{
			areaEl.focus();
		})
	}

	get userCanEdit()
	{
		return UsersModule.currentUser.admin ||
		UsersModule.currentUser.permitions.knowledge.manage
	}

	onDocumentNameChanged()
	{
		if(this.title.length > 50)
		{
			Notify.error('Максимальное количество симоволов 50')
			this.title = this.title.slice(0, 50)
		}
	}
	autoSize(ref:any)
	{
		const element = ref.target ? ref.target : ref;

		this.onDocumentNameChanged()

		if(window.innerWidth < 961)
		{
			if(element.className === 'document-name__title')
			{
				element.style.height = '10px';
			}
			else
			{
				element.style.height = '14px';
			}
		}
		else
		{
			element.style.height = '24px';
		}
		element.style.height = element.scrollHeight + 'px';
	}

	@Emit('editKnowledge')
	public editKnowledge():boolean
	{
		return true;
	}
	updateTitle() {
		this.$emit('updateTitle', this.title);
	}
	optionNameClicked()
	{
		this.isOptionsDocumentVisible = !this.isOptionsDocumentVisible;
		this.hasNameOption = !this.hasNameOption;
	}
	hideOptionsContent()
	{
		this.isOptionsDocumentVisible = false;
		this.hasNameOption = false;
	}
	// async deleteItem()
	// {
	// 	const id = Number(this.$route.params.documentId)
	// 	ApiClient.knowledge.deleteKnowledge(id).then(() =>
	// 	{
	// 		Notify.success('Знание удалено');
	// 		KnowledgeModule.fetchKnowledge();
	// 	}).finally(() =>
	// 	{
	// 		this.$router.push('/')
	// 	});
	// }

	async deleteItem() {
		const documentId = Number(this.$route.params.documentId);
		const editUser = await ApiClient.knowledge.getEditInfo(documentId);

		if (editUser.name)
		{
			Notify.error(`<div>В данный момент знание редактирует <a href="${MAIN_API_HOST.replace('/api', '')}/team/user/${editUser.id}"> ${editUser.name} </a>, свяжитесь с пользователем или попробуйте позже</div>`);
			this.showDeleteContext = false;
		}
		else
		{
			ApiClient.knowledge.deleteKnowledge(documentId).then(() =>
			{
				Notify.success('Знание удалено');
				KnowledgeModule.fetchKnowledge();
			}).finally(() =>
			{
				this.$router.push('/')
			});
		}
	}

	openDeleteContext()
	{
		this.showDeleteContext = true;
	}

	closeDeleteContext()
	{
		this.showDeleteContext = false;
	}

	@Emit('input')
	public onInput(): DocumentName
	{
		return {
			title: this.title,
			subtitle: this.subtitle,
		};
	}

	public onChange(): void
	{
		this.$nextTick(this.onInput.bind(this));
	}
}
