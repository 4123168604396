var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editorContainer",staticClass:"ediotr-wr"},[(_vm.$isDesktop)?_c('hr',{staticClass:"pulse-document__divider"}):_vm._e(),(_vm.editor && _vm.mode != 'view')?_c('div',{staticClass:"editor__toolbar-wrapper",class:{ 'editor__toolbar-wrapper--shadowed': _vm.toolbarOnTop }},[_c('div',{staticClass:"editor__toolbar"},[_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить эмодзи")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.showEmojiContext },attrs:{"slot":"reference"},on:{"click":_vm.emojiToggle},slot:"reference"},[_c('Icon',{attrs:{"data-name":'emoji',"name":"emoji","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить упоминание")])])]),_c('button',{staticClass:"tooltip-wrapper",attrs:{"slot":"reference"},on:{"click":_vm.handleClickMentions},slot:"reference"},[_c('Icon',{attrs:{"name":"mention","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить ссылку")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.editor.isActive('link') || _vm.showLinkModal },attrs:{"slot":"reference"},on:{"click":_vm.toggleLink},slot:"reference"},[_c('Icon',{attrs:{"name":"link","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить список")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.editor.isActive('blockquote') },attrs:{"slot":"reference"},on:{"click":_vm.toggleBlockQuote},slot:"reference"},[_c('Icon',{attrs:{"name":"list","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить нумерованный список")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.editor.isActive('orderedList') },attrs:{"slot":"reference"},on:{"click":_vm.toggleOrderList},slot:"reference"},[_c('Icon',{attrs:{"name":"ordered","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить маркированный список")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.editor.isActive('bulletList') },attrs:{"slot":"reference"},on:{"click":_vm.toggleBulletList},slot:"reference"},[_c('Icon',{attrs:{"name":"listdash","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить изображение")])])]),_c('button',{staticClass:"tooltip-wrapper",attrs:{"slot":"reference"},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.setSlider()
						.run()}},slot:"reference"},[_c('Icon',{attrs:{"name":"image","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить видео")])])]),_c('button',{staticClass:"tooltip-wrapper",attrs:{"slot":"reference","id":"add"},on:{"click":_vm.showVideo},slot:"reference"},[_c('Icon',{attrs:{"name":"youtube","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Вставить разделитель")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-disabled': _vm.editor.isActive('blockquote') || _vm.editor.isActive('orderedList') || _vm.editor.isActive('bulletList') },attrs:{"slot":"reference","disabled":_vm.editor.isActive('blockquote') || _vm.editor.isActive('orderedList') || _vm.editor.isActive('bulletList')},on:{"click":function($event){_vm.editor
						.chain()
						.focus()
						.setHorizontalRule()
						.run()}},slot:"reference"},[_c('Icon',{attrs:{"name":"divider","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Заголовок")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.editor.isActive('heading', { level: 1 }) },attrs:{"slot":"reference"},on:{"click":function($event){_vm.editor
							.chain()
							.focus()
							.toggleHeading({ level: 1 })
							.run()}},slot:"reference"},[_c('Icon',{attrs:{"name":"header","size":"18"}})],1)]),_c('popper',{attrs:{"trigger":"hover","visible-arrow":false,"options":{
				placement: 'top',
				modifiers: { offset: { offset: '0.10x' } }
			}}},[_c('div',{staticClass:"popper"},[_c('div',{staticClass:"tooltip-content"},[_c('p',[_vm._v("Заголовок")])])]),_c('button',{staticClass:"tooltip-wrapper",class:{ 'link-acitve': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"slot":"reference"},on:{"click":function($event){_vm.editor
							.chain()
							.focus()
							.toggleHeading({ level: 2 })
							.run()}},slot:"reference"},[_c('Icon',{attrs:{"name":"header2","size":"18"}})],1)])],1)]):_vm._e(),(_vm.showEmojiContext)?_c('VEmojiPicker',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.changeEmojiVisible),expression:"changeEmojiVisible"}],ref:"emoji",staticClass:"emojiCustomPicker",style:({ top: _vm.y + 20 + 'px', left: _vm.x + 'px' }),attrs:{"dark":true,"emojisByRow":7,"i18n":_vm.i18n,"emojiSize":24},on:{"select":_vm.showEmoji}}):_vm._e(),(_vm.showLinkModal)?_c('LinkConfirm',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeLinkModal),expression:"closeLinkModal"}],attrs:{"previousUrl":_vm.previousUrl},on:{"select":function($event){return _vm.setLink($event)},"close":function($event){_vm.showLinkModal = false;}}}):_vm._e(),_c('div',{staticClass:"editor-content__wrapper",on:{"keydown":_vm.handleKeyDown}},[_c('editor-content',{ref:"editor",attrs:{"extensions":_vm.extensions,"editor":_vm.editor},on:{"keydown":_vm.handleKeyDown},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),(_vm.videoContextMenu)?_c('PopupDialogDefault',{attrs:{"inputType":"text","message":"Вставьте ссылку на ролик","placeholderText":"http..."},on:{"save":function($event){return _vm.addVideo($event)},"closed":function($event){_vm.videoContextMenu = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }