import { Extension } from '@tiptap/core';

export default Extension.create({
    name: 'UnlinkOnSpace',
    addKeyboardShortcuts() {
        return {
            'Space': ({editor}) => {
                // получаем текущую позицию курсора
                const { from } = editor.state.selection
                // получаем текущий узел в текущей позиции курсора
                const node = editor.state.doc.nodeAt(from)
                
                // проверяем, является ли текущий узел ссылкой
                if (node && node.type.name === 'link') {
                    // получаем текст в текущей позиции курсора
                    const text = editor.state.doc.textBetween(from - 1, from)
                    
                    // проверяем, содержит ли текст пробел
                    if (text.includes(' ')) {
                        // получаем начальную позицию ссылки
                        const linkFrom = from - node.content.size
                        
                        // получаем конечную позицию ссылки
                        const linkTo = from - 1
                        
                        // удаляем ссылку из документа
                        editor.commands.deleteRange(linkFrom, linkTo)
                        
                        // вставляем текст без ссылки в документ
                        editor.commands.insertContent({
                            content: [
                                {
                                    type: 'text',
                                    text,
                                },
                            ],
                        })
                        
                        // устанавливаем курсор в конец вставленного текста
                        editor.commands.setTextSelection(linkFrom + text.length)
                    }
                }
            },
        };
    },
});
