import { reactive } from 'vue'

type EventCallback = (payload?: any) => void

interface EventBus {
  emit: (event: any, payload?: any) => void
  on: (event: any, callback: EventCallback) => void
  [key: string]: any
}

export const eventBus: EventBus = reactive({
  emit(event: any, payload?: any) {
    // eslint-disable-next-line
    // @ts-ignore
    if (this[event]) this[event].forEach((callback: EventCallback) => callback(payload))
  },
  on(event: any, callback: EventCallback) {
    // eslint-disable-next-line
    // @ts-ignore
    if (!this[event]) this[event] = []
    // eslint-disable-next-line
    // @ts-ignore
    this[event] = [ callback ]
  },
})